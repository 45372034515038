import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'

// import { CookiesProvider } from 'react-cookie'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  // <CookiesProvider>
  <Router>
    <Suspense>
      <GoogleOAuthProvider clientId="233905048961-5398o3ffio2mm9rgmgc0upgplftn2ipf.apps.googleusercontent.com">
        <App />
      </GoogleOAuthProvider>
    </Suspense>
  </Router>,
  // </CookiesProvider>
  // </React.StrictMode>,
)
reportWebVitals()
